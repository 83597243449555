import React, { useLayoutEffect, useContext, useEffect } from "react"
import ReactMarkdown from "react-markdown"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"

import { LangContext } from "../context/LangContext"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/anti/grid/grid"
import { CoreHeading } from "../components/gutenberg/core-heading"
import { CoreParagraph } from "../components/gutenberg/core-paragraph"
import { PostHeader } from "../components/anti/post/post"
import { replaceUnicode } from "../components/anti/utils/utils"
import { Breadcrumb } from "../components/anti/breadcrumb/breadcrumb"
import { SocialShare } from "../components/anti/social-links/social-links"
import { Cover } from "../components/anti/cover/cover"
import { Card } from "../components/anti/card/card"
import { AcfGallery } from "../components/gutenberg/acf-gallery"
import { getDate } from "../utils/get-date"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const NewsDetails = ({ location, pageContext }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        pageBy(uri: "news-events") {
          title
          slug
          adsNews {
            altTextAdsLeft
            imageAdLeft {
              sourceUrl
            }
            urlAdLeft {
              url
              target
            }
            altTextAdsRight
            imageAdRight {
              sourceUrl
            }
            urlAdRight {
              url
              target
            }
          }
        }
      }
    }
  `)

  const { lang, setLang } = useContext(LangContext)
  const wp =
    lang == "EN"
      ? pageContext.data.blocks
      : lang == "ID" && pageContext.data.translation !== null
      ? pageContext.data.translation.blocks
      : lang == "ID" && pageContext.data.translation == null
      ? pageContext.data.blocks
      : pageContext.data.blocks
  const coverMain =
    wp[0] !== undefined && wp[0].name === "acf/cover-main" ? wp[0] : false
  const currentCategory = pageContext.data.categories.nodes[0].slug
  const { seo } =
    pageContext.langKey === "en"
      ? pageContext.data
      : pageContext.data.translation || pageContext.data
  const news = pageContext.news
  let recommendedNews =
    news &&
    news.reduce((result, currentValue) => {
      if (
        currentValue.categories &&
        currentValue.categories.nodes[0].slug === currentCategory &&
        currentValue.slug !== pageContext.data.slug
      ) {
        result.push(currentValue)
      }
      return result
    }, [])

  const langKey =
    (location?.pathname?.split("/")[1] || location?.uri?.split("/")[1]) === "id"
      ? "ID"
      : lang === "ID"
      ? "ID"
      : "EN"
  const redirectAmpHtml = process.env.BASE_URL + location.pathname

  const adItem = staticData.wordPress.pageBy.adsNews

  const handleCopy = () => {
    const thisInput = document.createElement("INPUT")

    document.body.append(thisInput)
    thisInput.value = location.href
    thisInput.select()
    document.execCommand("copy")
    thisInput.remove()
    setTimeout(function() {
      document.getElementById(
        "copyButton"
      ).innerHTML = `<i class="fa fa-link"></i> <span class="tooltiptext">${
        langKey === "EN" ? "URL has copied" : "Tautan telah disalin"
      }</span>`
    }, 200)
    setTimeout(function() {
      document.getElementById("copyButton").innerHTML =
        '<i class="fa fa-link"></i>'
    }, 2000)
  }

  useEffect(() => {
    setLang(langKey)
  }, [langKey])

  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  // const SEODefaultImg = pageContext.langKey === "en"
  //   ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
  //   : pageContext?.data?.translation?.blocks[0]?.coverMain?.img?.mediaItemUrl
  //   || pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  const SEOFeaturedImg =
    pageContext.langKey === "en"
      ? pageContext.data.featuredImage?.sourceUrl
      : pageContext.data.translation?.featuredImage?.sourceUrl ||
        pageContext.data.featuredImage?.sourceUrl

  return (
    <Layout
      newsSlug={
        pageContext.langKey === "en"
          ? pageContext.data.translation?.slug || pageContext.data.slug
          : pageContext.data.slug
      }
    >
      <Helmet>
        <link
          rel="amphtml"
          href={redirectAmpHtml.replace("/news-events/", "/news-events/amp/")}
        />
        <link rel="canonical" href={redirectAmpHtml} />
      </Helmet>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={seo.opengraphImage?.sourceUrl || SEOFeaturedImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={redirectAmpHtml}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={seo.twitterImage?.sourceUrl || SEOFeaturedImg}
        isAmp={true}
        type="article"
      />

      <Container className="pt-4">
        <Breadcrumb path={location.pathname} className="d-none d-md-flex" />
      </Container>

      <Section className="post-details pt-2">
        <Container>
          <div className="row">
            <div className="col-lg-2 placebanner-right">
              {adItem?.imageAdLeft?.sourceUrl ? (
                <a
                  href={adItem?.urlAdLeft?.url ? adItem?.urlAdLeft?.url : "#"}
                  target={adItem?.urlAdLeft?.url !== "#" ? "_blank" : "_self"}
                >
                  <img
                    className="img-ads"
                    alt={adItem?.altTextAdsLeft ? adItem?.altTextAdsLeft : ""}
                    src={adItem?.imageAdLeft?.sourceUrl}
                  />
                </a>
              ) : (
                <></>
              )}
            </div>
            <div className="col-lg-8">
              {/* header */}
              <PostHeader
                title={
                  lang == "EN"
                    ? replaceUnicode(pageContext.data.title)
                    : lang == "ID" && pageContext.data.translation !== null
                    ? replaceUnicode(pageContext.data.translation.title)
                    : lang == "ID" && pageContext.data.translation === null
                    ? replaceUnicode(pageContext.data.title)
                    : replaceUnicode(pageContext.data.title)
                  // pageContext.data.title
                  //   ? replaceUnicode(pageContext.data.title)
                  //   : "Title"
                }
                category={
                  pageContext.data.categories.nodes[0] &&
                  pageContext.data.categories.nodes[0].name
                }
                date={getDate(pageContext.data.date, lang)}
              />
              <SocialShare
                className="pb-3"
                link={location.href}
                onClick={handleCopy}
              />

              {coverMain && coverMain.coverMain.img !== null && (
                <Cover
                  variant="fluid"
                  theme="dark"
                  img={coverMain.coverMain.img.mediaItemUrl}
                  // img={coverMain.coverMain.img.mediaItemUrl}
                  imgHeight="h-500px w-100"
                  className="pt-3 pb-main"
                />
              )}

              {/* body */}
              {wp.map((block, i) => {
                switch (block.name) {
                  case "core/heading":
                    return (
                      <CoreHeading data={block} key={`${block.name}${i}`} />
                    )
                  case "core/paragraph":
                    return (
                      <CoreParagraph
                        data={block}
                        key={`${block.name}${i}`}
                        className="text-justify"
                      />
                    )
                  case "core/image":
                    const imageURL = block.attributes.url.replace(
                      process.env.ROOT_ENV,
                      location.origin
                    )
                    return (
                      <div className="mb-4">
                        <img
                          alt="news image"
                          src={imageURL}
                          // src={block.attributes.url}
                          className="img-fluid"
                          key={`${block.name}${i}`}
                        />
                        <p
                          key={`caption-${i}`}
                          className="text-center m-0"
                          dangerouslySetInnerHTML={{
                            __html: block?.attributes?.caption,
                          }}
                          style={{ fontSize: "16px" }}
                        ></p>
                      </div>
                    )
                  case "acf/acfimage":
                    return (
                      <div className="mb-4">
                        <img
                          alt="news image"
                          src={block.imageBlock.img.mediaItemUrl}
                          className="img-fluid"
                          key={`${block.name}${i}`}
                        />
                        <ReactMarkdown
                          source={block?.imageBlock?.img?.caption}
                          escapeHtml={false}
                          key={`caption-${i}`}
                          className="text-center m-0 font-italic"
                          style={{ fontSize: "16px" }}
                        />
                      </div>
                    )
                  case "core/group":
                    return (
                      <div className={block.attributes.className}>
                        {block.innerBlocks && block.innerBlocks.map((block, i) => {
                          switch (block.name) {
                            case "core/heading":
                              return (
                                <CoreHeading
                                  data={block}
                                  key={`${block.name}${i}`}
                                />
                              )
                            case "core/paragraph":
                              return (
                                <CoreParagraph
                                  data={block}
                                  key={`${block.name}${i}`}
                                  className="text-justify"
                                />
                              )
                            case "core/image":
                              const imageURL = block.attributes.url.replace(
                                process.env.ROOT_ENV,
                                location.origin
                              )
                              return (
                                <div className="mb-4">
                                  <img
                                    alt="news image"
                                    src={imageURL}
                                    // src={block.attributes.url}
                                    className="img-fluid"
                                    key={`${block.name}${i}`}
                                  />
                                  <p
                                    key={`caption-${i}`}
                                    className="text-center m-0"
                                    dangerouslySetInnerHTML={{
                                      __html: block?.attributes?.caption,
                                    }}
                                    style={{ fontSize: "16px" }}
                                  ></p>
                                </div>
                              )
                            case "acf/acfimage":
                              return (
                                <div className="mb-4">
                                  <img
                                    alt="news image"
                                    src={block.imageBlock.img.mediaItemUrl}
                                    className="img-fluid"
                                    key={`${block.name}${i}`}
                                  />
                                  <ReactMarkdown
                                    source={block?.imageBlock?.img?.caption}
                                    escapeHtml={false}
                                    key={`caption-${i}`}
                                    className="text-center m-0 font-italic"
                                    style={{ fontSize: "16px" }}
                                  />
                                </div>
                              )
                            case "core/list":
                              return (
                                <ReactMarkdown
                                  source={block.attributes.values}
                                  escapeHtml={false}
                                  key={`${block.name}${i}`}
                                  className="px-3 pb-3"
                                />
                              )
                            default:
                              break
                          }
                        })}
                      </div>
                    )
                  case "core/list":
                    return (
                      <ReactMarkdown
                        source={block.attributes.values}
                        escapeHtml={false}
                        key={`${block.name}${i}`}
                        className="px-3 pb-3"
                      />
                    )
                  default:
                    break
                }
              })}
            </div>
            <div className="col-lg-2 placebanner-right">
              {adItem?.imageAdRight?.sourceUrl ? (
                <a
                  href={adItem?.urlAdRight?.url ? adItem?.urlAdRight?.url : "#"}
                  target={adItem?.urlAdRight?.url !== "#" ? "_blank" : "_self"}
                >
                  <img
                    className="img-ads"
                    alt={adItem?.altTextAdsRight ? adItem?.altTextAdsRight : ""}
                    src={adItem?.imageAdRight?.sourceUrl}
                  />
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Container>

        {/* <Container className="mw-md mb-5">
          
           <p className="text-muted font-weight-bold">
            {pageContext.data.tags.nodes.map((item, i) => {
              if (i === pageContext.data.tags.nodes.length - 1) {
                return ` ${item.name}`
              }
              return `${item.name},`
            })}
          </p> 
        </Container> */}
        <Container>
          {wp.map((block, i) => {
            switch (block.name) {
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-main"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              default:
                break
            }
          })}
          <h2 className="mb-4">
            {lang == "EN"
              ? "Discover more articles like this"
              : "Lihat Artikel Yang lain"}
          </h2>
          <div className="row">
            {recommendedNews.length > 0 &&
              recommendedNews.map((post, i) => {
                const postLang =
                  pageContext.langKey === "id"
                    ? post.translation || post
                    : post || post.translation
                if (recommendedNews.length > 1) {
                  if (i <= 3) {
                    return (
                      <div
                        className="col-12 col-md"
                        key={`recommendedNews-${postLang.title}-${i}`}
                      >
                        <Card
                          className="card-recommended-news mt-3"
                          variant="boxless"
                          colLeft="col-12"
                          colRight="col-12"
                          label={`${
                            postLang.categories.nodes[0].name
                          } - ${getDate(postLang.date, lang, { time: false })}`}
                          title={postLang.title}
                          text={
                            postLang.excerpt
                              ? postLang.excerpt
                              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                          }
                          img={
                            postLang.featuredImage
                              ? postLang.featuredImage.sourceUrl
                              : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                          }
                          imgHeight="h-ratio-3-2"
                          link={`/news-events/${post.slug}`}
                        />
                      </div>
                    )
                  }
                } else if (recommendedNews.length === 1) {
                  return (
                    <div
                      className="col-12 col-md-6"
                      key={`recommendedNews-${postLang.title}-${i}`}
                    >
                      <Card
                        className="card-recommended-news mt-3"
                        labelClassName="card-label-uppercase"
                        variant="boxless"
                        colLeft="col-12"
                        colRight="col-12"
                        label={`${(postLang.categories.nodes.length > 0 &&
                          postLang.categories.nodes[0].name.toUpperCase()) ||
                          ""} - ${getDate(postLang.date, lang, {
                          time: false,
                        })}`}
                        title={
                          (postLang.title && postLang.title.length > 40
                            ? `${postLang.title.substring(0, 40)}...`
                            : postLang.title) || "Title"
                        }
                        text={
                          postLang.excerpt
                            ? postLang.excerpt
                            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        }
                        img={
                          postLang.featuredImage
                            ? postLang.featuredImage.sourceUrl
                            : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                        }
                        imgHeight="h-ratio-3-2"
                        link={`/news-events/${post.slug}`}
                      />
                    </div>
                  )
                }
              })}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default NewsDetails
